/* eslint-disable eqeqeq */
/* eslint-disable default-case */
export function resolve(permission, currentUser) {
  if (currentUser) {
    switch (permission) {
      case WILDCARD:
        return true;
      case CREATE_PARTNER:
        return currentUser.permissions.CREATE_PARTNERS;
      case LIST_SUBSCRIPTIONS:
        return currentUser.permissions.SUBSCRIPTION_LIST_AND_DETAIL;
      case CREATE_OWNER_USER:
        return currentUser.permissions.CREATE_OWNERS;
      case CREATE_PARTNER_USER:
        return currentUser.permissions.CREATE_USERS_PARTNERS;
      case CREATE_CUSTOMER_USER:
        return currentUser.permissions.CREATE_CUSTOMERS;
      case EDIT_AVATAR_PERMISSIONS:
        return currentUser.permissions.EDIT_USER_PERMISSIONS;
      case EDIT_CUSTOMER_PERMISSIONS :
        return currentUser.permissions.EDIT_CUSTOMER_PERMISSIONS ;
      case DELETE_USER_ALL:
        return currentUser.permissions.DELETE_USER_AVATAR;
      case HARD_DELETE_USER:
        return currentUser.permissions.HARD_DELETE_USER;
      case DELETE_AVATAR_CUSTOMER:
        return currentUser.permissions.DELETE_AVATAR_CUSTOMER;
      case LIST_USERS:
        return currentUser.permissions.USERS_LIST_AND_DETAIL;
      case LIST_DOCUMENTS:
        return currentUser.permissions.DOCUMENTS_LIST_AND_DETAIL;
      case SEND_REMINDER:
        return currentUser.permissions.SEND_SIGNATURE_REMINDER;
      case DOWNLOAD_DOCUMENTS:
        return currentUser.permissions.DOWNLOAD_DOCUMENTS;
      case DELETE_DOCUMENTS:
        return currentUser.permissions.DELETE_DOCUMENTS;
      case CHANGE_DEVICE_DESCRIPTION:
        return currentUser.permissions.CHANGE_DEVICE_DESCRIPTION;
      case DELETE_DEVICE:
        return currentUser.permissions.DELETE_DEVICE;
      case CREATE_DELETE_CENTRALIZED_USERS:
        return currentUser.permissions.CREATE_DELETE_CENTRALIZED_USER;
      case CREATE_DELETE_STAMPER_CERT:
        return currentUser.permissions.CREATE_DELETE_STAMPER_CERT;
      case LIST_CUSTOMERS:
        return currentUser.permissions.CUSTOMERS_LIST_AND_DETAIL;
      case VISUALIZE_BILL:
        return currentUser.permissions.VISUALIZE_BILL;
      case LINK_CENTRALIZED_USERS:
        return currentUser.permissions.LINK_CENTRALIZED_USERS;
      case LINK_DEVICE:
        return currentUser.permissions.LINK_DEVICE;
      case LIST_PARTNERS:
        return currentUser.type === 'owner' ? true : false;
      case SUBS_VIEW_HYPER_PARTNER:
        return currentUser.type === 'owner' ? true : false;
      case SUBS_VIEW_HYPER_CUSTOMER:
        return currentUser.type === 'owner' ? true : currentUser.type === 'partner' ? true : false;
      case CUSTOMERS_PARTNER_COLUMN:
        return currentUser.type === 'owner' ? true : false;
      case CUSTOMERS_DETAIL_PARTNER:
        return currentUser.type === 'owner' ? true : false;
    }
  } else {
    return false
  }

}

export const CREATE_PARTNER = 1;
export const LIST_SUBSCRIPTIONS = 2;
export const CREATE_OWNER_USER = 3;
export const CREATE_PARTNER_USER = 4;
export const CREATE_CUSTOMER_USER = 5;
export const EDIT_AVATAR_PERMISSIONS = 6;
export const DELETE_USER_ALL = 7;
export const LIST_USERS = 8;
export const LIST_DOCUMENTS = 9;
export const SEND_REMINDER = 10;
export const DOWNLOAD_DOCUMENTS = 11;
export const DELETE_DOCUMENTS = 12;
export const CHANGE_DEVICE_DESCRIPTION = 13;
export const DELETE_DEVICE = 14;
export const CREATE_DELETE_CENTRALIZED_USERS = 15;
export const CREATE_DELETE_STAMPER_CERT = 16;
export const LIST_CUSTOMERS = 17;
export const VISUALIZE_BILL = 18;
export const EDIT_CUSTOMER_PERMISSIONS  = 19;
export const DELETE_AVATAR_CUSTOMER  = 20;
export const LINK_CENTRALIZED_USERS  = 21;
export const LINK_DEVICE  = 22;
export const HARD_DELETE_USER  = 23;
export const LIST_PARTNERS = 998;
export const WILDCARD = 999;

export const SUBS_VIEW_HYPER_PARTNER = 500;
export const SUBS_VIEW_HYPER_CUSTOMER = 501;
export const CUSTOMERS_PARTNER_COLUMN = 502
export const CUSTOMERS_DETAIL_PARTNER = 503



