import React from 'react';
import PropTypes from 'prop-types'
import './loading.css';

const Loading = (props) => {
	const { align, cover } = props
	return (
		<div className={`loading text-${align} cover-${cover}`}>
			<div class="lds-ring"><div></div><div></div><div></div><div></div></div>
		</div>
	)
}

Loading.propTypes = {
	size: PropTypes.string,
	cover: PropTypes.string
}

Loading.defaultProps = {
	align: 'center',
	cover: 'inline'
};

export default Loading